<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
        @onChange="change"
    />
  </div>
</template>


<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import "@wangeditor/editor/dist/css/style.css"
var _this;

export default{
  name: 'editoritem',
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: {

      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              debugger
            },
            customBrowseAndUpload(insertFn){
              _this.$emit('selectImages');
              // insertFn('http://120.78.213.240/upload/images/20220726/cdd9fb283008e8f531e42fc0f042f0cd.png')
            }

            // 自定义上传
            /*async customUpload(files, InsertFnType) {
              // file 即选中的文件
              // 自己实现上传，并得到图片 url alt href
              // this.$emit('selectImages',true);

              debugger
              // handleUploadImg(files, insertFn);
              // 最后插入图片
            }*/
          }
        }
      },
      mode: 'default', // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      // this.html = this.content
      // let data = editor.getMenuConfig('uploadImage')
    },
    change(html) {
      this.$emit('change', this.html)
      // this.editor.emit('customUpload')
      // this.editor.dangerouslyInsertHtml(`<h1>标题</h1><p>文本 <b>加粗</b></p>`)
    },
    insertImages(data) {
      debugger
      this.editor.dangerouslyInsertHtml(data)
    },
    setHtml(val) {
      this.html = val
    }
  },
  created() {
    this.html = this.content
  },
  mounted() {
    _this = this
    // this.html = this.content
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   // this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    //   this.html = this.content
    // }, 1500)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>